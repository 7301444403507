import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content"]

  connect() {
    this.contentTarget.style.maxHeight = "0"
    this.contentTarget.style.opacity = "0"
    this.contentTarget.style.overflow = "hidden"
  }

  toggle() {
    if (this.contentTarget.style.maxHeight === "0px") {
      this.show()
    } else {
      this.hide()
    }
  }

  show() {
    const height = this.contentTarget.scrollHeight
    this.contentTarget.style.maxHeight = `${height}px`
    this.contentTarget.style.opacity = "1"
    this.contentTarget.style.transition = "max-height 0.3s ease-in-out, opacity 0.2s ease-in-out"
  }

  hide() {
    this.contentTarget.style.maxHeight = "0px"
    this.contentTarget.style.opacity = "0"
  }
} 