import { Controller } from "@hotwired/stimulus"
import Choices from 'choices.js'

export default class extends Controller {
    static targets = [ "dropdown" ]
    connect() {
        this.dropdown = new Choices(this.dropdownTarget, {
            removeItemButton: true,
            itemSelectText: '',
            fuseOptions: {
            threshold: 0.4
            }
        });

        document.addEventListener('turbo:before-stream-render', this.handleStreamAppend.bind(this));
    }
    disconnect() {
        this.dropdown.destroy()
        document.removeEventListener('turbo:before-stream-render', this.handleStreamAppend.bind(this));
    }

    handleStreamAppend(event) {
        const outerTemplate = event.target.querySelector('template');
        if (!outerTemplate) return;
      
        // Find the inner template
        const innerTemplate = outerTemplate.content.querySelector('template');
        if (!innerTemplate) return;
        const action = innerTemplate.dataset.action;
        const tagValue = innerTemplate.dataset.tagValue;

        if (action === 'remove' && tagValue) {
            this.dropdown.removeActiveItemsByValue(tagValue);
            event.preventDefault();
            return;
        }
          
      
        const option = innerTemplate.content.querySelector('option');
        if (option) {
          this.dropdown.setValue([
            { value: option.value, label: option.textContent, selected: true },
          ]);
          event.preventDefault();
        }      
    }

}